const Nftseries0 = () => {
  return (
    <div className="mx-auto max-w-7xl px-6 sm:mt-0 lg:px-8 " id="nftseries0">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
        <h2 data-aos="fade-up" className="text-3xl font-bold tracking-tight text-gold sm:text-4xl"><br />NFT Series 0 Investor Round</h2>
        <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
          <div data-aos="fade-up" className="lg:w-full lg:max-w-2xl lg:flex-auto">
            <p className="text-xl leading-8 text-white">
              The Shelter Series 0 NFT has a unique design made specifically for Shelter and it's main goal of sheltering animals around the world! Series 0 is meant as the way to become an investor in the project and comes with the following perks:
<br />•$45 worth of $SHLTR token (Investors Cliff/Vesting scheme).
<br />•Future WL spots guaranteed.
<br />•Discord Holders Chat access. 
<br />•Each NFT gives one 10% discount on a future NFT in series 1-4. 
<br />•Voting right for naming the Shelter Puppies! And more!<br />
  
To become an investor and enjoy all perks, please visit our mint page <a href="https://truffle.wtf/project/shelter-official-nft-series-0"> <font color="blue"> here </font> </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nftseries0
